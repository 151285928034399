import React from "react";

function Certificate() {
  const currentPath = window.location.href;
  const certificateID = currentPath.split("/").slice(-1);

  return (
    <main>
      <section>
        <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
          <div className="max-full mx-auto bg-white shadow-md overflow-hidden lg:max-w-7xl">
            <div className="lg:flex">
              <div className="text-center">
                <p className="uppercase tracking-wide text-5xl text-primaryGreen font-semibold p-8">
                  Certificado de finalización
                </p>
                <div className="hidden lg:block">
                  {downloadButtons(
                    currentPath,
                    `/certificado/${certificateID}.pdf`
                  )}
                </div>
              </div>
              <div className="flex">
                <img
                  className="w-full object-cover p-8"
                  src={`images/${certificateID}.png`}
                  alt="Certificado de finalización"
                />
              </div>
              <div className="lg:hidden">
                {downloadButtons(
                  currentPath,
                  `/certificado/${certificateID}.pdf`
                )}
              </div>
            </div>

            {learnMore()}
          </div>
        </div>
      </section>
    </main>
  );
}

function downloadButtons(siteURL, pdfURL) {
  return (
    <div className="flex flex-col p-4 items-center ">
      <a
        href={pdfURL}
        target="_blank"
        className="bg-primaryGreen p-4 rounded-md text-white  w-80"
      >
        <div className="inline-flex items-center">
          <svg
            class="w-4 h-4 mr-2 fill-current text-white"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
          </svg>
          <span>Descargar el certificado</span>
        </div>
      </a>
      <div className="shadow-md mt-4 p-4 rounded-md text-sm w-80">
        {siteURL}
      </div>
    </div>
  );
}

function learnMore() {
  return (
    <div className="text-center text-sm m-8 text-primaryGreen underline">
      <a href="https://academiabauer.com/#cursos">
        Aprende más sobre nuestros cursos de alemán.
      </a>
    </div>
  );
}

export default Certificate;
