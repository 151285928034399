import React from "react";
import { useEffect, useState } from "react";
import Certificate from "./components/Certificate";
import CertificateNotFound from "./components/CertificateNotFound";
import CertificateHeader from "./components/Header";
import certificateIdList from "./data";

function App() {
  const path = window.location.pathname;
  const certificateID = path.substring(1, path.length);

  return (
    <main>
      <CertificateHeader />
      <section>
        <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
          {certificateIdList.find((v) => certificateID === v) ? (
            <Certificate />
          ) : (
            <CertificateNotFound />
          )}
        </div>
      </section>
    </main>
  );
}

export default App;
