import React from "react";

function NoCertificate() {
  return (
    <main>
      <section>
        <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
          <div className="max-full mx-auto bg-white shadow-md overflow-hidden lg:max-w-7xl">
            <div className="text-center">
              <p className=" tracking-wide text-5xl text-primaryGreen font-semibold p-8">
                No pudimos encontrar un certificado para el enlace solicitado.
              </p>
            </div>
            {contactUs()}
          </div>
        </div>
      </section>
    </main>
  );
}

function contactUs() {
  return (
    <div className="text-center m-8 ">
      Si crees que este fue un error contáctate con nosotros:{" "}
      <a href="mailto:info@academiabauer.com" className="text-primaryGreen">
        info@academiabauer.com
      </a>{" "}
      o por nuestras redes sociales.
    </div>
  );
}

export default NoCertificate;
