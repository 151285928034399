const certificates = [
  "00015BA0",
  "00015BF1",
  "00015BF2",
  "00015C06",
  "AD7D00B",
  "AD7D00D",
  "AD7D00E",
  "ADB701B",
  "ADB701D",
  "ADB701E",
  "ADC7022",
  "ADC7023",
  "ADD901F",
  "ADD9024",
  "ADDD027",
  "ADE4028",
  "ADE4029",
  "ADF202B",
  "ADF202D",
  "ADF4031",
  "ADF4032",
  "ADF4033",
  "ADED02A",
  "AE9A061",
  "AECB065",
  "AE7F059",
  "AE80058",
  "AF3307D",
  "AEDA06B",
  "ADB7093",
  "AF3A07E",
  "AE16036",
  "AFAA095",
  "NON_",
];
export default certificates;
